/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';

export default function Image(props) {

    const [email, setEmail] = React.useState("")

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...{ name: email, email, message: 'New signup notification' } })
        })
            .then(() => {
                alert("Email sent!")
                setEmail("")
            })
            .catch((error) => alert(error.message));
    };
    return (
        <div className="image-wrapper" style={{
            backgroundImage: `url(${props.img})`
        }}>
            <div className="image-body h-[90%] w-[90%] max-w-screen-xl" style={{
                backgroundImage: `url(${props.img})`,
                backgroundPosition: props.position,
            }}>
                <img className="w-[200px] lg:w-[400px] absolute mt-4 lg:mt-16 z-10 mx-auto left-0 right-0" src="/logo-icon.png" alt='titan tenders logo' />
                <div className="relative z-10 text-center uppercase mx-4 lg:mx-[64px] xl:mx-[128px] mt-[6rem] lg:mt-[12rem] text-white self-center w-full">
                    <p className="display-1 text-[14px] sm:text-[18px] lg:text-[22px] tracking-[2px] lg:tracking-[6px] leading-none">
                        {props.text}
                    </p>
                    <p className="display-1 text-[60px] lg:text-[120px] leading-none mb-8">
                        fall 2024
                    </p>
                    <div className="md:flex items-center justify-center">
                        <form name="contact" method="post" onSubmit={handleSubmit}>
                            <input className="input-class text-[20px] h-[30px] md:border-r-0" onChange={(e) => setEmail(e.target.value)} value={email} type="email" id="email" placeholder="YOUR EMAIL" name="email" required />
                            <button className="text-xs max-w-[250px] md:w-max w-full font-semibold text-black bg-white uppercase border-[1px] border-white px-4 py-[6px]" type="submit">
                                get updates
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
}