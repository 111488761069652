import React from 'react';
import Image from "./Image";
import './index.css';
import { images } from "./constants"

// Import Swiper React components & Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

function App() {
  const swiperRef = React.useRef(null);
  const [isFirstSlide, setIsFirstSlide] = React.useState(true);
  const [isLastSlide, setIsLastSlide] = React.useState(false);

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleSwiperUpdate = (swiper) => {
    setIsFirstSlide(swiper.isBeginning);
    setIsLastSlide(swiper.isEnd);
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<div class="${className}"></div>`;
    },
  };

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        pagination={pagination}
        navigation={{
          prevEl: ".custom-prev",
          nextEl: ".custom-next",
          disabledClass: "swiper-button-disabled",
        }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper"
        onInit={(swiper) => {
          swiperRef.current = swiper;
          handleSwiperUpdate(swiper);
        }}
        onSlideChange={(swiper) => handleSwiperUpdate(swiper)}
      >
        {images.map((img, index) => (
          <SwiperSlide pagination={true} modules={[Pagination]} key={index}>
            <Image img={img.img} text={img.text} position={img.position} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="hidden max-w-screen-xl lg:flex w-[90%] mx-auto px-4 md:px-8 lg:px-16 h-[90%] md:mt-[-120px] mb-[100px] text-white flex justify-end items-center gap-x-4 relative z-30">
        <div className={`${isFirstSlide ? "cursor-auto" : "cursor-pointer"} flex items-center custom-prev h-4`}
          onClick={handlePrevClick}
          disabled={isFirstSlide}
        >
          <div className={`${isFirstSlide ? "swiper-button-disabled" : ""} custom-hr-prev border w-16 border-white`} />
        </div>
        <div className={`${isLastSlide ? "cursor-auto" : "cursor-pointer"} flex items-center custom-next h-4`}
          onClick={handleNextClick}
          disabled={isLastSlide}
        >
          <div className={`${isLastSlide ? "swiper-button-disabled" : ""} custom-hr-next border w-16 border-white}`} />
        </div>
        <div className='uppercase'>
          coming soon
        </div>
      </div>
    </>
  );
}

export default App;
